import { useEffect, useState } from "react";

// * utils
import useAxios from "../../../utils/initAxios";
import { IUserInfo } from "../../../context/UserInfoContext/interface";

export default function useFetchUserInfoApi({ userId }: { userId: string }) {
    const [userInfo, setUserInfo] = useState<IUserInfo | undefined>();
    const [{ data: response }, execute] = useAxios(
        {
            url: `/api/v1/user/profile?userId=${userId}`,
            method: "GET",
        },
        {
            manual: true,
        },
    );

    useEffect(() => {
        if (response) {
            setUserInfo(response?.user);
        }
    }, [response]);

    return { userInfo, handleFetchUserInfo: execute };
}
