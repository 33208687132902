import React from "react";
import { ISvgProps } from "./interface";

export default function SuccessToastIconSvg({
    width = "24",
    height = "24",
    fill = "#157826",
    className,
}: ISvgProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <g clipPath="url(#clip0_858_5321)">
                <path
                    d="M15.9998 2.66666C8.63984 2.66666 2.6665 8.64 2.6665 16C2.6665 23.36 8.63984 29.3333 15.9998 29.3333C23.3598 29.3333 29.3332 23.36 29.3332 16C29.3332 8.64 23.3598 2.66666 15.9998 2.66666ZM15.9998 26.6667C10.1198 26.6667 5.33317 21.88 5.33317 16C5.33317 10.12 10.1198 5.33333 15.9998 5.33333C21.8798 5.33333 26.6665 10.12 26.6665 16C26.6665 21.88 21.8798 26.6667 15.9998 26.6667ZM22.1198 10.1067L13.3332 18.8933L9.87984 15.4533L7.99984 17.3333L13.3332 22.6667L23.9998 12L22.1198 10.1067Z"
                    fill={fill}
                />
            </g>
        </svg>
    );
}
