import React, { useEffect, useState } from "react";

// * interface
import { IToastProps } from "./interface";

// * utils
import getToastStyle, { ToastType } from "./utils";

// * assets
import CloseSvg from "../../assets/svg/CloseSvg";
import WarningSvg from "../../assets/svg/WarningSvg";
import ErrorToastIconSvg from "../../assets/svg/ErrorToastIconSvg";
import SuccessToastIconSvg from "../../assets/svg/SuccessToastIconSvg";
import InfoToastIconSvg from "../../assets/svg/InfoToastIconSvg";

export default function Toast({ type, message, click }: IToastProps) {
    // * STATES
    const [dismissToast, setDismissToast] = useState(false);

    // * get the toast style base on the type
    const toastStyle = getToastStyle(type);

    // * use this effect to auto slide the toast before disappearing
    useEffect(() => {
        setTimeout(() => {
            setDismissToast(true);
        }, 4500);
    }, []);

    // * use this function to get the notification icon
    function renderNotificationIcon(): React.ReactElement {
        if (type === ToastType.ERROR) {
            return <ErrorToastIconSvg width="28" height="28" fill="#A51E1E" />;
        }
        if (type === ToastType.SUCCESS) {
            return <SuccessToastIconSvg width="28" height="28" fill="#157826" />;
        }
        if (type === ToastType.WARNING) {
            return <WarningSvg width="28" height="28" fill="#CF8B26" />;
        }
        if (type === ToastType.INFO) {
            return <InfoToastIconSvg width="28" height="28" fill="#1E56A5" />;
        }
        return <div />;
    }

    return (
        <div
            className={`flex items-stretch rounded-[8px] border ${toastStyle?.backgroundColor} ${
                toastStyle?.border
            } ${dismissToast ? "animate-slideOut translate-x-[500px]" : "animate-slideIn"}`}
        >
            <div className="pl-4 pr-2 flex items-center">{renderNotificationIcon()}</div>
            <div className="px-2 grow min-h-[60px] overflow-auto">
                <p className="text-base font-semibold leading-7 h-full flex items-center">
                    {message}
                </p>
            </div>
            <div
                className="pl-2 pr-4 flex items-center rounded-r-[8px] cursor-pointer bg-secondary-grey"
                onClick={() => {
                    setDismissToast(true);
                    click();
                }}
            >
                <CloseSvg width="18" height="18" fill="#6E7682" />
            </div>
        </div>
    );
}
