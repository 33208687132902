import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";

// * interface
import { IUserInfo } from "./interface";

// * utils
import Utils, { COOKIE_KEY_NAMES } from "../../utils";

// * context
import { ToastContext } from "../ToastContext";

// * services
import useFetchUserInfoApi from "../../services/api/UserInfo/fetchUserInfoApi";
import errorHandling from "../../services/utils/errorHandler";

// * components
import Loading from "../../components/Loading";

export const UserInfoContext = createContext<{
    userInfo: IUserInfo | undefined;
    subscription: Array<string>;
    setUserId: (value: string) => void;
}>({
    userInfo: {},
    subscription: [],
    setUserId: (value: string) => value,
});

export default function UserInfoProvider({ children }: { children: React.ReactElement }) {
    // * STATES
    const [userId, setUserId] = useState(Utils.getValueFromCookie(COOKIE_KEY_NAMES.userId) || "");
    const [subscription, setSubscription] = useState<string[]>([]);
    const [fetchUserInfoLoading, setFetchUserInfoLoading] = useState(false);

    // * CONTEXT
    const dispatch = useContext(ToastContext);

    // * call api to fetch the user info from api
    const { userInfo, handleFetchUserInfo } = useFetchUserInfoApi({ userId: userId });

    /**
     * * effect runs on every refresh. fetches the user info based on the
     * * user id and updates the context
     */
    useEffect(() => {
        let timer: NodeJS.Timeout;
        function fetchUserInfo() {
            handleFetchUserInfo()
                .catch((fetchUserInfoError) => {
                    // * check if the request is not cancled after unmounting the component!
                    if (!axios.isCancel(fetchUserInfoError)) {
                        const error = errorHandling(fetchUserInfoError);
                        dispatch(error);
                    }
                })
                .finally(() => {
                    setFetchUserInfoLoading(false);
                });
        }

        if (userId) {
            setFetchUserInfoLoading(true);
            timer = Utils.debounce(() => {
                fetchUserInfo();
            }, 700);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [userId]);

    /**
     * * once the user info is fetched we need to update the subscription
     * * and provide that information in the application by updating the context
     */
    useEffect(() => {
        // * extract subscription data of the user and update the context
        setSubscription(userInfo?.studentData?.paidSubscription || []);
    }, [userInfo]);

    return (
        <UserInfoContext.Provider value={{ userInfo, subscription, setUserId }}>
            {fetchUserInfoLoading ? (
                <div className="w-[100vw] h-[100vh] flex items-center justify-center">
                    <Loading background="bg-accent-500" />
                </div>
            ) : (
                children
            )}
        </UserInfoContext.Provider>
    );
}
