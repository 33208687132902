import React from "react";
import { ISvgProps } from "./interface";

export default function ErrorToastIconSvg({
    width = "24",
    height = "24",
    fill = "#157826",
    className,
}: ISvgProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M14.6665 20H17.3332V22.6667H14.6665V20ZM14.6665 9.33333H17.3332V17.3333H14.6665V9.33333ZM15.9865 2.66667C8.6265 2.66667 2.6665 8.64 2.6665 16C2.6665 23.36 8.6265 29.3333 15.9865 29.3333C23.3598 29.3333 29.3332 23.36 29.3332 16C29.3332 8.64 23.3598 2.66667 15.9865 2.66667ZM15.9998 26.6667C10.1065 26.6667 5.33317 21.8933 5.33317 16C5.33317 10.1067 10.1065 5.33333 15.9998 5.33333C21.8932 5.33333 26.6665 10.1067 26.6665 16C26.6665 21.8933 21.8932 26.6667 15.9998 26.6667Z"
                fill={fill}
            />
        </svg>
    );
}
