export enum ToastType {
    ERROR = "ERROR",
    SUCCESS = "SUCCESS",
    WARNING = "WARNING",
    INFO = "INFO",
}

export enum ToastAction {
    PUSH_NOTIFICATION = "PUSH_NOTIFICATION",
    REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION",
}

export default function getToastStyle(type: string) {
    switch (type) {
        case ToastType.SUCCESS:
            return {
                backgroundColor: "bg-[#EAF8ED]",
                border: "border-[#1EA534]",
            };
        case ToastType.ERROR:
            return {
                backgroundColor: "bg-[#F8EAEA]",
                border: "border-[#E83636]",
            };
        case ToastType.WARNING:
            return {
                backgroundColor: "bg-[#F8F3EA]",
                border: "border-[#E8A136]",
            };
        case ToastType.INFO:
            return {
                backgroundColor: "bg-[#F0F4FA]",
                border: "border-[#3680E8]",
            };
        default:
            return {
                backgroundColor: "bg-[#E9E9E9]",
                border: "border-[#ddd]",
            };
    }
}
