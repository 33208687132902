import validator from "validator";
import Cookies from "js-cookie";

// * assets
import EasyDifficultySvg from "../assets/images/easy.svg";
import HardDifficultySvg from "../assets/images/hard.svg";
import MediumDifficultySvg from "../assets/images/medium.svg";
// import ClockSvg from "../assets/images/clock.svg";
import MixedSvg from "../assets/images/shuffle-svgrepo-com.svg";

// * ENUMS
export enum DifficultyLevels {
    EASY = "EASY",
    MEDIUM = "MEDIUM",
    HARD = "HARD",
    MIXED = "MIXED",
}

// * CONSTANTS
export const NAME_REGEXP = /^[0-9a-zA-Z ]+$/;
export const NUMBER_REGEXP = /^[0-9]+$/;
export const ALPHABET_REGEXP = /^[a-zA-Z ]+$/;
export const EMAIL_REGEXP = /^[a-zA-Z0-9@. ]+$/;
export const COOKIE_KEY_NAMES = {
    email: "email",
    userId: "userId",
    subjectId: "subjectId",
};

export default class Utils {
    static capitalizeFirstLetter(string: string): string {
        return string
            ?.toLowerCase()
            ?.split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
    }

    static debounce(fn: () => void, delay: number) {
        const timer = setTimeout(fn, delay);
        return timer;
    }

    static isValidEmail(email: string): boolean {
        return validator.isEmail(email);
    }

    static isPasswordStrong(password: string): boolean {
        return validator.isStrongPassword(password, {
            minLength: 8,
            minLowercase: 1,
            minUppercase: 1,
            minNumbers: 1,
            minSymbols: 1,
        });
    }

    static addCookie(key: string, value: string) {
        const cookieExpiryTime = new Date();
        cookieExpiryTime.setTime(cookieExpiryTime.getTime() + 24 * 3600 * 1000); // expires in 24 hours
        Cookies.set(key, value, {
            expires: cookieExpiryTime,
        });
    }

    static getValueFromCookie(key: string) {
        return Cookies.get(key);
    }

    static removeCookie(key: string) {
        Cookies.remove(key);
    }

    static deleteAllCookies() {
        const cookies = document.cookie.split(";");
        cookies.map((cookie) => {
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
            Cookies.remove(name.trim());
            return null;
        });
    }

    static isLoggedIn() {
        if (Cookies.get("token")) {
            return true;
        }
        return false;
    }

    static getDifficultyLevelStyle(type: string) {
        switch (type) {
            case DifficultyLevels.EASY:
                return {
                    svg: EasyDifficultySvg,
                    text: "easy",
                    className:
                        "text-xs leading-xs uppercase font-semibold bg-success-100 text-success-700 border border-success-700",
                };
            case DifficultyLevels.MEDIUM:
                return {
                    svg: MediumDifficultySvg,
                    text: "medium",
                    className:
                        "text-xs leading-xs uppercase font-semibold bg-warning-100 text-warning-500 border border-warning-500",
                };
            case DifficultyLevels.HARD:
                return {
                    svg: HardDifficultySvg,
                    text: "hard",
                    className:
                        "text-xs leading-xs uppercase font-semibold bg-error-100 text-error-500 border border-error-500",
                };
            case DifficultyLevels.MIXED:
                return {
                    svg: MixedSvg,
                    text: "mixed",
                    className:
                        "text-xs leading-xs uppercase font-semibold bg-neutral-100 text-neutral-600 border border-neutral-600",
                };
            default:
                return {
                    svg: MixedSvg,
                    text: "all",
                    className:
                        "text-xs leading-xs uppercase font-semibold bg-neutral-100 text-neutral-600 border border-neutral-600",
                };
        }
    }
}
