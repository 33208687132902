import React from "react";
import "./loading.css";

export default function Loading({ background = "bg-neutral-400" }) {
    return (
        <div className="loader">
            <div className={background}></div>
            <div className={background}></div>
            <div className={background}></div>
            <div className={background}></div>
            <div className={background}></div>
        </div>
    );
}
