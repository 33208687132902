import { AxiosError } from "axios";

// * utils
import { ToastAction, ToastType } from "../../components/Toast/utils";

// * use this function for error handling of apis
export default function errorHandling(err: AxiosError) {
    if (err?.response) {
        // eslint-disable-next-line
        const { data }: any = err.response;
        return {
            type: ToastAction.PUSH_NOTIFICATION,
            payload: {
                id: Math.floor(Math.random() * 100),
                type: ToastType.ERROR,
                message: data?.message,
            },
        };
    }
    return {
        type: ToastAction.PUSH_NOTIFICATION,
        payload: {
            id: Math.floor(Math.random() * 100),
            type: ToastType.ERROR,
            message: "Something went wrong",
        },
    };
}
