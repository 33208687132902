import React, { createContext, useReducer } from "react";

// * interface
import { INotificationAction } from "./interface";
import { IToastProps } from "../../components/Toast/interface";

// * utils
import { ToastAction } from "../../components/Toast/utils";

// * components
import Toast from "../../components/Toast";

// eslint-disable-next-line
export const ToastContext = createContext<any>([]);

export default function NotificationProvider({ children }: { children: React.ReactElement }) {
    // initialising the notification array using useReducer hook;
    const [state, dispatch] = useReducer(
        // eslint-disable-next-line
        (state: any, action: INotificationAction) => {
            // based on the action make state changes
            switch (action.type) {
                // append a new notification
                case ToastAction.PUSH_NOTIFICATION: {
                    setTimeout(() => {
                        dispatch({
                            type: ToastAction.REMOVE_NOTIFICATION,
                            payload: {
                                id: action.payload.id,
                            },
                        });
                    }, 5000);
                    return [...state, action.payload];
                }

                // remove a desired notification
                case ToastAction.REMOVE_NOTIFICATION: {
                    return state.filter(
                        (toast: INotificationAction) => toast.id !== action.payload.id,
                    );
                }

                // return notification array as default
                default:
                    return state;
            }
        },
        [],
    );

    // use this for remove a toast before expiry.
    function handleRemoveToast(id: number, duration: number) {
        setTimeout(() => {
            dispatch({
                type: ToastAction.REMOVE_NOTIFICATION,
                payload: {
                    id,
                },
            });
        }, duration);
    }

    return (
        <ToastContext.Provider value={dispatch}>
            {state.length > 0 && (
                <div className="fixed z-10 top-[10%] right-0 w-full max-w-[500px]">
                    <div className="h-full max-h-[90vh] overflow-y-auto overflow-x-hidden">
                        {state.map((toast: IToastProps) => {
                            return (
                                <div className="p-2" key={toast.id}>
                                    <Toast
                                        type={toast.type}
                                        message={toast.message}
                                        click={() => handleRemoveToast(toast?.id ?? 0, 500)}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
            {children}
        </ToastContext.Provider>
    );
}
