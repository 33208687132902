import React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import QuisWiseRoutes from "./routes";

function App() {
    // Function to handle copy, paste, and context menu (right click) events
    const handleEvent = (event: React.ClipboardEvent | React.MouseEvent) => {
        event.preventDefault(); // Prevent the default action
        alert("This action has been disabled."); // Optional: Alert the user
    };

    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID ?? ""}>
            <div
                onCopy={handleEvent}
                onPaste={handleEvent}
                onCut={handleEvent}
                onContextMenu={handleEvent} // Disable right click
            >
                <QuisWiseRoutes />
            </div>
        </GoogleOAuthProvider>
    );
}

export default App;
