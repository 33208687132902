import React from "react";
import { Navigate } from "react-router-dom";
import Utils from "../utils";
import queryString from "query-string";

export default function PrivateRoutes({
    children,
}: {
    children: React.ReactElement;
}): React.ReactElement {
    if (!Utils.isLoggedIn() && location.pathname !== `/login`) {
        return <Navigate to={`/login?${queryString.stringify({ next: location.pathname })}`} />;
    }
    return children;
}
