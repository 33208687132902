import axios from "axios";
import { makeUseAxios } from "axios-hooks";

// * utils
import Utils from ".";

const REQUEST_TIMEOUT = 60000;

const headers: { Authorization: string | undefined } = {
    Authorization: `Bearer ${Utils.getValueFromCookie("token")}`,
};

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers,
    timeout: REQUEST_TIMEOUT,
});

const useAxios = makeUseAxios({
    axios: instance,
});

export default useAxios;
