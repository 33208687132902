import React from "react";
import { ISvgProps } from "./interface";

export default function WarningSvg({
    width = "65",
    height = "65",
    fill = "#3680E8",
    className,
}: ISvgProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 65 65`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M32.5007 16.223L52.8944 51.4584H12.1069L32.5007 16.223ZM32.5007 5.41675L2.70898 56.8751H62.2923L32.5007 5.41675ZM35.209 43.3334H29.7923V48.7501H35.209V43.3334ZM35.209 27.0834H29.7923V37.9167H35.209V27.0834Z"
                fill={fill}
            />
        </svg>
    );
}
