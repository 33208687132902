import { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// * routes
import PrivateRoutes from "./privateRoutes";

// * context
import NotificationProvider from "../context/ToastContext";
import UserInfoProvider from "../context/UserInfoContext";

// * pages
const LandingPage = lazy(() => import("../pages/LandingPage"));
const Login = lazy(() => import("../pages/Auth/components/Login"));
const SignUp = lazy(() => import("../pages/Auth/components/SignUp"));
const ForgotPassword = lazy(() => import("../pages/Auth/components/ForgotPassword"));
const EmailVerifiction = lazy(() => import("../pages/Auth/components/EmailVerifiction"));
const Onboarding = lazy(() => import("../pages/Onboarding"));
const EmailVerified = lazy(() => import("../pages/Auth/components/EmailVerified"));
const CreatePassword = lazy(() => import("../pages/Auth/components/CreatePassword"));
const Application = lazy(() => import("../pages/Application"));
const CheckoutSuccess = lazy(
    () => import("../pages/Application/components/Plans/components/CheckoutSuccess"),
);

export default function QuisWiseRoutes() {
    return (
        <NotificationProvider>
            <UserInfoProvider>
                <BrowserRouter>
                    <Suspense>
                        <Routes>
                            <Route path="/" element={<LandingPage />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/sign-up" element={<SignUp />} />
                            <Route path="/forgot-password" element={<ForgotPassword />} />
                            <Route path="/verify-email" element={<EmailVerifiction />} />
                            <Route path={"/onboarding"} element={<Onboarding />} />
                            <Route path={"/email-verified"} element={<EmailVerified />} />
                            <Route path={"/create-password"} element={<CreatePassword />} />
                            <Route
                                path="/Plans/checkout-success"
                                element={
                                    <PrivateRoutes>
                                        <CheckoutSuccess />
                                    </PrivateRoutes>
                                }
                            />
                            <Route
                                path={"/*"}
                                element={
                                    <PrivateRoutes>
                                        <Application />
                                    </PrivateRoutes>
                                }
                            />
                        </Routes>
                    </Suspense>
                </BrowserRouter>
            </UserInfoProvider>
        </NotificationProvider>
    );
}
