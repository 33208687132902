import React from "react";
import { ISvgProps } from "./interface";

export default function InfoToastIconSvg({
    width = "24",
    height = "24",
    fill = "#1E56A5",
    className,
}: ISvgProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <g clipPath="url(#clip0_858_5314)">
                <path
                    d="M14.6665 9.33332H17.3332V12H14.6665V9.33332ZM14.6665 14.6667H17.3332V22.6667H14.6665V14.6667ZM15.9998 2.66666C8.63984 2.66666 2.6665 8.63999 2.6665 16C2.6665 23.36 8.63984 29.3333 15.9998 29.3333C23.3598 29.3333 29.3332 23.36 29.3332 16C29.3332 8.63999 23.3598 2.66666 15.9998 2.66666ZM15.9998 26.6667C10.1198 26.6667 5.33317 21.88 5.33317 16C5.33317 10.12 10.1198 5.33332 15.9998 5.33332C21.8798 5.33332 26.6665 10.12 26.6665 16C26.6665 21.88 21.8798 26.6667 15.9998 26.6667Z"
                    fill={fill}
                />
            </g>
        </svg>
    );
}
